import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

const heartColor={
  color:'red'
}
const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-section about">
            <h2>About Us</h2>
            <p>ABU Construction, your trusted partner in construction excellence. We specialize in delivering top-quality construction services, from innovative design to meticulous execution.We are a company dedicated to providing the best services to our customers.</p>
            <p>copyright © 2024 all rights reserved</p>
          </div>
          <div className="footer-section links">
            <h2>Quick Links</h2>
            <ul>
            <li><Nav.Link as={Link} to="/" className="nav-item active">Home</Nav.Link></li>
              {/* <li><Nav.Link as={Link} to="/services" className="nav-item">Services</Nav.Link></li> */}
              <li><Nav.Link as={Link} to="/about" className="nav-item">About</Nav.Link></li>
              <li><Nav.Link as={Link} to="/contact" className="nav-item">Contact</Nav.Link></li>
            </ul>
          </div>
          <div className="footer-section contact">
            <h2>Contact Us</h2>
            <p>Email: info@adiraiabuconstruction.com</p>
            <p>Phone: +91 96007 70061</p>
            <div className="social-media">
        <a href="#header" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <FaFacebookF />
        </a>        
        <a href="#header" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
          <FaInstagram />
        </a>       
      </div>
          </div>
        </div>
      </div>      
      <a href="https://edsolargroup.com" style={{textDecoration:'none'}}>
      <p className='eds'>
        Made By <FontAwesomeIcon icon={faHeart} style={heartColor}/> EDSOLAR - GROUP
      </p></a>  
    </footer>
  );
};

export default Footer;
