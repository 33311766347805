import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './style.css'
import Footer from './Footer'
import Interior1 from './IMG/Interior1.jpg';
import Interior2 from './IMG/Interior2.jpg';
import Interior3 from './IMG/Interior3.jpg';
import Interior4 from './IMG/Interior4.jpg';
import Interior5 from './IMG/Interior5.jpg';
import Interior6 from './IMG/Interior6.jpg';
import Interior7 from './IMG/Interior7.jpg';
import Interior8 from './IMG/Interior8.jpg';
import Interior9 from './IMG/Interior9.jpg';
import Interior10 from './IMG/Interior10.jpg';
import Interior11 from './IMG/Interior11.jpg';
import Interior12 from './IMG/Interior12.jpg';
// import Interior13 from './IMG/Interior13.jpg';
import Interior14 from './IMG/Interior14.jpg';
import Interior15 from './IMG/Interior15.jpg';
import Interior16 from './IMG/Interior16.jpg';
import Interior17 from './IMG/Interior17.jpg';
import Interior18 from './IMG/Interior18.jpg';
import Interior19 from './IMG/Interior19.jpg';
import Interior20 from './IMG/Interior20.jpg';
import Interior21 from './IMG/Interior25.jpg';
import Interior22 from './IMG/Interior22.jpg';
import Interior23 from './IMG/Interior23.jpg';
import Interior24 from './IMG/Interior24.jpg';
import Interior25 from './IMG/Interior21.jpg';
import Interior26 from './IMG/Interior26.jpg';



const Interior = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    { original: Interior1 , thumbnail:  Interior1},
    { original: Interior2,thumbnail: Interior2 },
    { original: Interior3, thumbnail: Interior3 },
    { original: Interior4, thumbnail: Interior4 },
    { original: Interior6, thumbnail: Interior6 },
    { original: Interior7, thumbnail: Interior7 },
    { original: Interior9, thumbnail: Interior9 },
    { original: Interior10, thumbnail: Interior10 },
    { original: Interior16, thumbnail: Interior16 },
    { original: Interior17, thumbnail: Interior17 },
    { original: Interior18, thumbnail: Interior18 },
    { original: Interior19, thumbnail: Interior19 },
    { original: Interior15, thumbnail: Interior15 },
    { original: Interior14, thumbnail: Interior14 },
    { original: Interior12, thumbnail: Interior12 },
    { original: Interior5, thumbnail: Interior5 },
    { original: Interior8, thumbnail: Interior8 },
    { original: Interior11, thumbnail: Interior11 },
    { original: Interior20, thumbnail: Interior20 },
    { original: Interior21, thumbnail: Interior21 },
    { original: Interior22, thumbnail: Interior22 },
    { original: Interior23, thumbnail: Interior23 },
    { original: Interior24, thumbnail: Interior24 },
    { original: Interior25, thumbnail: Interior25 },
    { original: Interior26, thumbnail: Interior26 },
  ];

  const openGallery = (index) => {
    setCurrentImageIndex(index);
    setIsOpen(true);
  };

  const closeGallery = () => {
    setIsOpen(false);
  };

  return (
    <>
    <div className="commercial">
    <h1 style={{ fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic'  }}>Interior</h1>
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-item" onClick={() => openGallery(index)}>
            <img src={image.thumbnail} alt={`Thumbnail ${index + 1}`} />
          </div>
        ))}
      </div>
      
      {isOpen && (
        <div className="gallery-overlay" onClick={closeGallery}>
          <div className="gallery-container" onClick={(e) => e.stopPropagation()}>
            <ImageGallery
              items={images}
              startIndex={currentImageIndex}
              showThumbnails={false}
              showFullscreenButton={false}
              showPlayButton={false}
              onSlide={(index) => setCurrentImageIndex(index)}
            />
          </div>
        </div>
      )}
    </div>
    <Footer/>
  </>
  );
};

export default Interior;

