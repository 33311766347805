import React, { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import Footer from './Footer';
import './style.css'
import Residency1 from './IMG/slidebg1.jpg';
import Residency2 from './IMG/Residency1.jpg';
import Residency3 from './IMG/Residency2.jpg';
import Residency4 from './IMG/Residency3.jpg';
import Residency5 from './IMG/Slidebg2.jpg';
import Residency6 from './IMG/Residency6.jpg';
import Residency7 from './IMG/slidebg3.jpg';
import Residency8 from './IMG/Residency4.jpg';
import Residency9 from './IMG/Residency5.jpg';
import Residency10 from './IMG/Residency7.jpg';
import Residency11 from './IMG/Residency8.jpg';

const Residency = () => {
  {
    const [isOpen, setIsOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    const images = [
      { original: Residency1 , thumbnail:  Residency1},
      { original: Residency2,thumbnail: Residency2 },
      { original: Residency3, thumbnail: Residency3 },
      { original: Residency4, thumbnail: Residency4 },
      { original: Residency5, thumbnail: Residency5 },
      { original: Residency6, thumbnail: Residency6 },
      { original: Residency7, thumbnail: Residency7 },
      { original: Residency8, thumbnail: Residency8 },
      { original: Residency9, thumbnail: Residency9 },
      { original: Residency10, thumbnail: Residency10 },
      { original: Residency11, thumbnail: Residency11 },
    ];
  
    const openGallery = (index) => {
      setCurrentImageIndex(index);
      setIsOpen(true);
    };
  
    const closeGallery = () => {
      setIsOpen(false);
    };
  
    return (
    <>
      <div className="commercial">
      <h1 style={{ fontFamily: 'Agency FB, sans-serif', fontStyle: 'italic'  }}>Residency</h1>

        <div className="image-grid">
          {images.map((image, index) => (
            <div key={index} className="image-item" onClick={() => openGallery(index)}>
              <img src={image.thumbnail} alt={`Thumbnail ${index + 1}`} />
            </div>
          ))}
        </div>
        
        {isOpen && (
          <div className="gallery-overlay" onClick={closeGallery}>
            <div className="gallery-container" onClick={(e) => e.stopPropagation()}>
              <ImageGallery
                items={images}
                startIndex={currentImageIndex}
                showThumbnails={false}
                showFullscreenButton={false}
                showPlayButton={false}
                onSlide={(index) => setCurrentImageIndex(index)}
              />
            </div>
          </div>
        )}
      </div>
      <Footer/>
    </>
    );
  };
}

export default Residency;
